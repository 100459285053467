main {
    padding: 0 20px;
}

.container {
    width: 100%;
    padding: 0 30px;
}

.navbar {
    background-color: #fafafa;
    border-radius: 0;
    border: 0;
    margin-bottom: 0;
}

.navbar-default .navbar-brand {
    padding-top: 10px;
}

.navbar .navbar-brand img {
    height: 34px;
}

.navbar .navbar-brand button {
    margin-top: 10px;
}

.navbar-default .navbar-brand, .navbar-default .navbar-nav > li > a, .navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
    color: black;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: black;
}

.nav > li > a {
    padding: 0;
}

.form-signin, .rbc-calendar {
    animation: fadeInSlide 1s;
}

.rbc-calendar {
    position: absolute;
    padding-top: 45px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.total-hours {
    position: absolute;
    right: 179px;
    top: 36px;
    text-align: left;
    padding: 10px;
    z-index: 10;
    font-size: 13px;
    width: 175px;
}

.total-hours p {
    margin: 0;
}

.rbc-toolbar {
    margin: 15px;
}

.rbc-toolbar button {
    border: none;
    text-transform: uppercase;
    padding: 8px 15px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.02);
    font-size: 14px;
}

.rbc-toolbar button:first-child {
    background: rgba(0, 0, 0, 0.06);
}

.navbar-nav > li > a {
    padding-top: 10px;
    border: 0;
}

.rbc-toolbar button, .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    box-shadow: none;
    outline: none;
}

.rbc-btn-group button + button {
    margin-left: 0;
}

.rbc-toolbar .rbc-toolbar-label {
    font-size: 18px;
    text-align: left;
    opacity: 0.54;
}

.rbc-header {
    font-size: 40px;
    text-align: left;
    padding: 5px 10px;
    white-space: pre-wrap;
    color: #9e9e9e;
    font-weight: lighter;
}

.rbc-header:first-line {
    font-size: 14px;
}

.rbc-header:first-letter {
    text-transform: uppercase;
}

.rbc-calendar .rbc-today ~ .rbc-header {
    color: black;
}

.rbc-day-slot .rbc-event {
    opacity: 0;
    animation: fadeIn .7s forwards cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 3px;
    border: none;
    border-bottom: 2px solid white;
}

.rbc-day-slot .rbc-event-content {
    font-size: 12px;
    font-weight: bold;
}

.rbc-current-time-indicator {
    height: 2px;
    background-color: #bf4d4b;
}

.rbc-today {
    background-color: #fff2f9;
    color: rgb(66, 133, 244);
}


@keyframes fadeInSlide {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fadeIn {
    from {
        transform: translateX(40px) scale(0.8);
        opacity: 0;
    }
    to {
        transform: translateX(0px) scale(1.0);
        opacity: 1;
    }
}


