.rbc-addons-dnd .rbc-row-content {
  pointer-events: none;
}
.rbc-addons-dnd .rbc-row-content .rbc-show-more,
.rbc-addons-dnd .rbc-row-content .rbc-event:not(.rbc-addons-dnd-dragging) {
  pointer-events: all;
}
.rbc-addons-dnd .rbc-addons-dnd-over {
  background-color: rgba(0, 0, 0, 0.3);
}
.rbc-addons-dnd .rbc-events-container {
  pointer-events: none;
}
.rbc-addons-dnd .rbc-event {
  transition: opacity 150ms;
  pointer-events: all;
}
.rbc-addons-dnd.rbc-addons-dnd-is-dragging .rbc-event {
  pointer-events: none;
  opacity: .50;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-anchor {
  width: 100%;
  height: 10px;
  text-align: center;
  margin-left: -5px;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-anchor:first-child {
  position: absolute;
  top: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-anchor:last-child {
  position: absolute;
  bottom: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-anchor .rbc-addons-dnd-resize-icon {
  display: none;
  border-top: 3px double;
  margin: 0 auto;
  width: 10px;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-anchor:hover {
  cursor: ns-resize;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-anchor:hover .rbc-addons-dnd-resize-icon {
  display: block;
}
.rbc-addons-dnd .rbc-addons-dnd-resizable-month-event {
  position: relative;
  user-drag: none;
}
.rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor {
  width: 20px;
  height: 20px;
  top: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:hover {
  cursor: ew-resize;
}
.rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:first-child {
  position: absolute;
  left: -5px;
}
.rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:last-child {
  position: absolute;
  right: -5px;
}

main {
    padding: 0 20px;
}

.container {
    width: 100%;
    padding: 0 30px;
}

.navbar {
    background-color: #fafafa;
    border-radius: 0;
    border: 0;
    margin-bottom: 0;
}

.navbar-default .navbar-brand {
    padding-top: 10px;
}

.navbar .navbar-brand img {
    height: 34px;
}

.navbar .navbar-brand button {
    margin-top: 10px;
}

.navbar-default .navbar-brand, .navbar-default .navbar-nav > li > a, .navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
    color: black;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: black;
}

.nav > li > a {
    padding: 0;
}

.form-signin, .rbc-calendar {
    -webkit-animation: fadeInSlide 1s;
            animation: fadeInSlide 1s;
}

.rbc-calendar {
    position: absolute;
    padding-top: 45px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.total-hours {
    position: absolute;
    right: 179px;
    top: 36px;
    text-align: left;
    padding: 10px;
    z-index: 10;
    font-size: 13px;
    width: 175px;
}

.total-hours p {
    margin: 0;
}

.rbc-toolbar {
    margin: 15px;
}

.rbc-toolbar button {
    border: none;
    text-transform: uppercase;
    padding: 8px 15px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.02);
    font-size: 14px;
}

.rbc-toolbar button:first-child {
    background: rgba(0, 0, 0, 0.06);
}

.navbar-nav > li > a {
    padding-top: 10px;
    border: 0;
}

.rbc-toolbar button, .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    box-shadow: none;
    outline: none;
}

.rbc-btn-group button + button {
    margin-left: 0;
}

.rbc-toolbar .rbc-toolbar-label {
    font-size: 18px;
    text-align: left;
    opacity: 0.54;
}

.rbc-header {
    font-size: 40px;
    text-align: left;
    padding: 5px 10px;
    white-space: pre-wrap;
    color: #9e9e9e;
    font-weight: lighter;
}

.rbc-header:first-line {
    font-size: 14px;
}

.rbc-header:first-letter {
    text-transform: uppercase;
}

.rbc-calendar .rbc-today ~ .rbc-header {
    color: black;
}

.rbc-day-slot .rbc-event {
    opacity: 0;
    -webkit-animation: fadeIn .7s forwards cubic-bezier(0.25, 0.8, 0.25, 1);
            animation: fadeIn .7s forwards cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 3px;
    border: none;
    border-bottom: 2px solid white;
}

.rbc-day-slot .rbc-event-content {
    font-size: 12px;
    font-weight: bold;
}

.rbc-current-time-indicator {
    height: 2px;
    background-color: #bf4d4b;
}

.rbc-today {
    background-color: #fff2f9;
    color: rgb(66, 133, 244);
}


@-webkit-keyframes fadeInSlide {
    from {
        opacity: 0;
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}


@keyframes fadeInSlide {
    from {
        opacity: 0;
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@-webkit-keyframes fadeIn {
    from {
        -webkit-transform: translateX(40px) scale(0.8);
                transform: translateX(40px) scale(0.8);
        opacity: 0;
    }
    to {
        -webkit-transform: translateX(0px) scale(1.0);
                transform: translateX(0px) scale(1.0);
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        -webkit-transform: translateX(40px) scale(0.8);
                transform: translateX(40px) scale(0.8);
        opacity: 0;
    }
    to {
        -webkit-transform: translateX(0px) scale(1.0);
                transform: translateX(0px) scale(1.0);
        opacity: 1;
    }
}



.spinner {
    margin: 40vh auto 0;
    width: 70px;
    text-align: center;
}

.spinner > div {
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}
h1, h2, h3 {
    font-weight: lighter;
}

h1 {
    font-size: 42px;
}
h2 {
    font-size: 32px;
}

.container .wrapper {
    margin-top: 0;
}
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: white;
}

.navbar {
    background: transparent;
}

.wrapper {
    margin-top: 80px;
    margin-bottom: 80px;
}

a {
    color: #581834;
}

a:hover {
    color: #6d3950;
}

.btn {
    margin-left: 5px;
}
.btn-primary {
    background-color: #581834;
    border: 0;
}

.btn-primary:hover {
    background-color: #6d3950;
}

.form-signin {
    max-width: 380px;
    padding: 15px 35px 45px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
    margin-bottom: 30px;
    font-size: 20px;
}

.form-signin .checkbox {
    font-weight: normal;
}

.form-signin .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.form-signin input[type="text"] {
    margin-bottom: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.mt-15 {
    margin-top: 15px;
}
