h1, h2, h3 {
    font-weight: lighter;
}

h1 {
    font-size: 42px;
}
h2 {
    font-size: 32px;
}

.container .wrapper {
    margin-top: 0;
}