body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: white;
}

.navbar {
    background: transparent;
}

.wrapper {
    margin-top: 80px;
    margin-bottom: 80px;
}

a {
    color: #581834;
}

a:hover {
    color: #6d3950;
}

.btn {
    margin-left: 5px;
}
.btn-primary {
    background-color: #581834;
    border: 0;
}

.btn-primary:hover {
    background-color: #6d3950;
}

.form-signin {
    max-width: 380px;
    padding: 15px 35px 45px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
    margin-bottom: 30px;
    font-size: 20px;
}

.form-signin .checkbox {
    font-weight: normal;
}

.form-signin .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.form-signin input[type="text"] {
    margin-bottom: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.mt-15 {
    margin-top: 15px;
}